import React, { useEffect, useState } from 'react';
import TopNavigation, { TopNavigationProps } from '@amzn/awsui-components-react/polaris/top-navigation';
import { Images, Languages, URLS, UserActionNames } from '../constants/Constants';
import { IsItDown } from './common/IsItDown';
import i18n from 'src/i18n';
import { useBundle, useLocalizationContext } from '@amzn/react-arb-tools';
import { DEFAULT_LC_BUILDER } from '../constants/Constants';
import { Spinner } from '@amzn/awsui-components-react';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { forceAwakensBaseState } from 'src/stores/app';
import { useState as useHookState } from "@hookstate/core";
import { Notifications } from './common/Notifications';

interface ITopNav {
  darkMode: boolean;
  setDarkModeCallback: Function;
  setShowSelectSite: Function;
  siteCode: string | undefined;
  username: string;
}

export const TopNav = (props: ITopNav) => {
  console.log(`TopNav() props is ${JSON.stringify(props)}`);

  const [darkMode, setDarkMode] = useState<boolean>(props.darkMode);
  const forceAwakensState = useHookState(forceAwakensBaseState);

  const [bundle, isBundleLoading] = useBundle('components.Navigation');
  const { localizationContext, setLocalizationContext } = useLocalizationContext();

  const i18nStrings = {
    searchIconAriaLabel: 'Search',
    searchDismissIconAriaLabel: 'Close search',
    overflowMenuTriggerText: 'More',
    overflowMenuTitleText: 'More'};

  let stage = 'gamma';
  console.log(`TopNav() stage is ${stage}`);

  const [ title, setTitle ] = useState<string>(() => {
    let title = 'Force Awakens Configuration';
    if (stage.toLowerCase() !== 'prod') title += ` - ${stage.toUpperCase()}`;
    return title;
  });

  const handleLanguageChange = (e: any) => {
		const language = e.detail.id;
		i18n.changeLanguage(language);
		setLocalizationContext(DEFAULT_LC_BUILDER.withLocale(language).build());
	};
  
  useEffect(() => {
    setDarkMode(props.darkMode);
  }, [props.darkMode]);

  if (isBundleLoading) return <Spinner/>;

  let topMenuItems: TopNavigationProps.Utility[] = [
    {
      type: 'button',
      iconName: 'edit', 
      text: props.siteCode ? `${props.siteCode} - ${bundle.getMessage('change-site')}` : `${bundle.getMessage('change-site')}`,
      onClick: () => {
        props.setShowSelectSite(true);
      }
    },
    {
      type: 'button',
      iconName: darkMode ? 'caret-up-filled' : 'caret-down-filled', 
      text: darkMode ? bundle.getMessage('light') : bundle.getMessage('dark'),
      onClick: () => {
        createUserAction({
          actionName: UserActionNames.UIModeChange,
          username: forceAwakensBaseState.username.value,
          parameters: JSON.stringify(
            {
              mode: darkMode ? bundle.getMessage('light') : bundle.getMessage('dark'),
            }) 
        });
        console.log(`TopNav() darkMode onClick()`);
        setDarkMode(!darkMode);
        localStorage.setItem('darkMode', (!darkMode).toString());
        props.setDarkModeCallback(!darkMode);
        forceAwakensState.darkMode.set(!darkMode);
      }
    },
    {
      type: 'button',
      text: bundle.getMessage('guardian-hub'),
      ariaLabel: 'Guardian Hub',
      href: URLS.GuardianHub,
      external: true,
      badge: false,
      disableUtilityCollapse: true,
    },
    {
      type: 'menu-dropdown',
        text: bundle.getMessage('language'),
        items: Languages,
        onItemClick: handleLanguageChange,
    },
    {
      type: 'menu-dropdown',
      text: bundle.getMessage('help'),
      items: [
        {
          external: true,
          externalIconAriaLabel: ' (opens in new tab)',
          href: URLS.RiverGuide,
          id: 'guide',
          text: bundle.getMessage('contact-us'),
        },
        {
          external: true,
          externalIconAriaLabel: ' (opens in new tab)',
          text: bundle.getMessage('user-guide'),
          id: 'guide',
          href: URLS.UserGuide,
        },
      ]
    },
    {
      type: 'button',
      text: `${props.username}@ ${forceAwakensState.isAdmin.value ? '(admin)' : ''}`,
      iconName: 'user-profile',
      disableTextCollapse: true,
    }
  ];

  return (
    <>
      <IsItDown />
      <Notifications />
      <TopNavigation
        i18nStrings={i18nStrings}
        identity={{
          href: '#',
          logo: {
            src: Images.home,
            alt: bundle.getMessage('home')
          },
          title: title,
        }}
        data-testid='TopNav'
        utilities={topMenuItems}
      />
    </>
  );
}
